import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import '../styles/style.scss'

export default () => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <footer className='footer center'>
        <div className='content has-text-centered'>
          <p>
            <small>Copyright &copy; 2021</small>
          </p>
        </div>
      </footer>
    )}
  />
)
