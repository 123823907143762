import React from 'react';
import '../styles/style.scss';

import Navbar from './navbar';

const Header = ({ siteTitle }) => (
	<section className="">
		<Navbar />
	</section>
);

export default Header;
